/* BASE */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*,
*:before,
*:after {
  box-sizing: border-box; }

html {
  background-color: #ff914b;
  background-image: linear-gradient(#ff914b, #ffdf43); }

img {
  height: auto;
  max-width: 100%; }

body {
  background-color: #34313f;
  position: relative;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #fff;
  overflow-x: hidden;
  line-height: 1.5;
  background-image: url("/accent-top.svg");
  background-size: 100%;
  background-repeat: no-repeat; }
  body.is-open {
    overflow: hidden; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Condensed", sans-serif;
  line-height: 1.25; }

h1 {
  font-size: 40px;
  font-weight: 300; }
  @media screen and (min-width: 600px) {
    h1 {
      font-size: 52px; } }

h2 {
  font-size: 26px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px; }
  @media screen and (min-width: 600px) {
    h2 {
      font-size: 32px; } }

button {
  cursor: pointer;
  background-color: #52aac8;
  text-transform: uppercase;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 1px;
  padding: 18px 36px;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.25);
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: box-shadow 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; }
  button:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.25), 0 6px 20px 5px rgba(0, 0, 0, 0.1); }

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0; }

a {
  text-decoration: none;
  color: #52aac8;
  font-weight: bold;
  transition: 0.15s ease-in-out color; }
  a:hover {
    color: #3891af; }

.Main {
  overflow-x: hidden; }

/* View */
.Logo {
  position: relative;
  max-width: 160px;
  z-index: 1; }

.Header {
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  background-color: #34313f;
  z-index: 2;
  padding: 15px 20px;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.3), 0 0 15px 3px rgba(0, 0, 0, 0.1); }
  @media screen and (min-width: 600px) {
    .Header {
      padding: 15px 25px; } }
  .Header-logo {
    font-size: 0px; }

.Header-nav {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(52, 49, 63, 0.95); }
  .Header-nav.is-open {
    display: flex;
    flex-direction: column;
    padding-top: 150px; }
  @media screen and (min-width: 960px) {
    .Header-nav {
      display: flex;
      margin-left: auto;
      position: static;
      width: auto;
      height: auto;
      background: none; } }

.Header-nav > a {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  font-size: 28px;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  padding: 15px 5px;
  display: block;
  text-align: center;
  transition: background-color 0.15s ease-in-out; }
  .Header-nav > a:hover {
    background-color: rgba(82, 170, 200, 0.35);
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.25); }
  @media screen and (min-width: 960px) {
    .Header-nav > a {
      margin-left: 30px;
      padding: 5px 20px;
      font-size: 15px; }
      .Header-nav > a:hover {
        background-color: transparent;
        box-shadow: none; }
      .Header-nav > a::before {
        content: '';
        display: inline-block;
        position: relative;
        top: -1px;
        width: 8px;
        height: 8px;
        background-color: #52aac8;
        border-radius: 50%;
        margin-right: 10px;
        vertical-align: middle; }
      .Header-nav > a.is-active {
        border-radius: 4px;
        background-color: rgba(82, 170, 200, 0.35);
        box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.25); } }

.Mobile-nav {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  transition: 0.3s background-color ease; }
  @media screen and (min-width: 960px) {
    .Mobile-nav {
      display: none; } }

.Hamburger {
  background: none;
  border: 0;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  height: 23px;
  width: 30px;
  margin-left: auto;
  z-index: 1; }
  .Hamburger.is-open .Hamburger-lines {
    background-color: transparent;
    visibility: hidden; }
  .Hamburger.is-open .Hamburger-lines::before,
  .Hamburger.is-open .Hamburger-lines::after {
    visibility: visible;
    top: 0;
    transition-delay: 0s, 0s, 0.2s;
    background-color: #fff; }
  .Hamburger.is-open .Hamburger-lines::before {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
            transform: rotate3d(0, 0, 1, 45deg); }
  .Hamburger.is-open .Hamburger-lines::after {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg); }
  @media screen and (min-width: 960px) {
    .Hamburger {
      display: none; } }
  .Hamburger-lines, .Hamburger-lines:before, .Hamburger-lines:after {
    cursor: pointer;
    border-radius: 1px;
    height: 3px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    display: block;
    content: '';
    transition: top 0.1s 0.2s, background-color 0.3s, -webkit-transform 0.15s cubic-bezier(0.37, 0.74, 0.15, 1.45);
    transition: top 0.1s 0.2s, background-color 0.3s, transform 0.15s cubic-bezier(0.37, 0.74, 0.15, 1.45);
    transition: top 0.1s 0.2s, background-color 0.3s, transform 0.15s cubic-bezier(0.37, 0.74, 0.15, 1.45), -webkit-transform 0.15s cubic-bezier(0.37, 0.74, 0.15, 1.45); }
  .Hamburger-lines {
    top: 50%;
    left: 50%;
    margin-top: -2px;
    margin-left: -15px; }
    .Hamburger-lines::before {
      top: -8px; }
    .Hamburger-lines::after {
      top: 8px; }
  .Hamburger:hover .Hamburger-lines,
  .Hamburger:hover .Hamburger-lines::before,
  .Hamburger:hover .Hamburger-lines::after {
    background-color: white; }

.Filter {
  background-color: rgba(255, 255, 255, 0.97);
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 3px; }
  @media screen and (max-width: 959px) {
    .is-open .Filter {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      overflow-y: scroll;
      border-radius: 0; } }
  @media screen and (min-width: 960px) {
    .Filter {
      min-width: 250px;
      flex-grow: 0;
      overflow: hidden;
      margin-bottom: 25px;
      margin-right: 25px;
      width: 25%; } }

.Filter-count {
  background-color: #ddd;
  font-family: 'Roboto Condensed', sans-serif;
  text-align: center;
  padding: 10px;
  letter-spacing: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (min-width: 960px) {
    .Filter-count {
      padding: 20px; } }

.Filter-cats {
  display: none; }
  @media screen and (min-width: 960px) {
    .Filter-cats {
      display: block; } }
  @media screen and (max-width: 959px) {
    .is-open .Filter-cats {
      display: block; } }

.Filter-cat {
  flex: 1 1;
  border-bottom: 1px solid #ddd; }
  @media screen and (min-width: 600px) and (max-width: 960px) {
    .Filter-cat {
      border-right: 1px solid #ddd;
      border-bottom: none; } }

.Filter-title {
  background: none;
  border: 0;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #34313f;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left; }
  .Filter-title i {
    color: #52aac8;
    font-weight: bold;
    font-size: 30px; }

.Filter-title:hover {
  background-color: #d9f1f9; }

.Filter-title:focus {
  outline: none; }

.Filter-list {
  padding: 15px; }

.Filter-open,
.Filter-close {
  font-size: 15px;
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  padding: 15px 40px;
  font-weight: bold;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
  letter-spacing: 0.5px;
  display: inline-block;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #34313f;
  color: #34313f;
  padding: 10px 20px;
  transition: background-color 0.15s ease-in-out; }
  .Filter-open i,
  .Filter-close i {
    font-size: 18px;
    margin-right: 5px;
    vertical-align: middle; }
  .Filter-open:hover,
  .Filter-close:hover {
    background-color: #d9f1f9; }
  @media screen and (min-width: 600px) {
    .Filter-open,
    .Filter-close {
      margin-top: 0; } }
  @media screen and (min-width: 960px) {
    .Filter-open,
    .Filter-close {
      display: none !important; } }

.Filter-close {
  display: none; }
  .is-open .Filter-close {
    display: inline-block; }

.is-open .Filter-open {
  display: none; }

.Product {
  min-height: 750px;
  text-align: center;
  padding: 75px 0;
  margin: 0 2px; }
  @media screen and (min-width: 1200px) {
    .Product {
      max-width: 960px;
      padding-top: 75px;
      padding-bottom: 100px;
      margin: 0 auto; } }
  .Product .Loader {
    margin-top: 60px; }
  .Product > * {
    margin: 0 auto; }

.Product-title {
  letter-spacing: 0;
  text-transform: none;
  font-size: 24px;
  color: #34313f; }
  @media screen and (min-width: 600px) {
    .Product-title {
      margin-bottom: 15px; } }

.Product-items {
  margin-top: 20px; }

.Product-item {
  margin-bottom: 3px;
  text-align: left; }
  @media screen and (min-width: 1200px) {
    .Product-item {
      margin-bottom: 6px; } }

.Product-link {
  background-color: rgba(255, 255, 255, 0.97);
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  align-items: center;
  transition: -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  color: #34313f;
  position: relative;
  overflow: hidden;
  pointer-events: none;
  padding: 0; }
  @media screen and (min-width: 600px) {
    .Product-link {
      min-height: 160px;
      padding: 20px 160px;
      align-items: center;
      pointer-events: auto; } }

@media screen and (min-width: 600px) {
  .Product-link::before {
    content: "";
    position: absolute;
    transition: background-color 0.15s ease-in-out;
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    background-color: transparent; }
  .Product-link:hover {
    -webkit-transform: scale(1.025);
            transform: scale(1.025);
    color: #34313f; }
    .Product-link:hover .Product-ctaBtn {
      background-image: linear-gradient(45deg, #ff914b, #ffdf43);
      -webkit-transform: none;
              transform: none;
      box-shadow: none;
      color: #34313f; }
  .Product-link:hover::before {
    background-color: #ff914b; } }

.Product-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 20px;
  float: left; }
  @media screen and (min-width: 600px) {
    .Product-img {
      float: none;
      position: absolute;
      left: 20px;
      top: 20px;
      margin: 0; } }

.Product-header {
  display: flex;
  width: 100%; }
  @media screen and (max-width: 599px) {
    .Product-header {
      padding: 20px; } }

.Product-icon {
  color: #52aac8; }

.Product-body {
  background-color: rgba(221, 221, 221, 0.5);
  width: 100%; }
  .Product-body dl {
    display: flex;
    flex-direction: column;
    padding: 20px; }
  @media screen and (min-width: 600px) {
    .Product-body {
      background-color: transparent; }
      .Product-body dl {
        padding: 0;
        flex-wrap: wrap;
        flex-direction: row; } }

.Product-arrow {
  color: #52aac8; }

.Product-definition,
.Product-ctaItem {
  font-size: 13px; }

.Product-definition {
  margin: 5px 0; }
  .Product-definition dd,
  .Product-definition dt {
    display: inline-block;
    color: #34313f; }
  .Product-definition dt {
    font-weight: bold;
    padding-right: 10px;
    text-align: right; }
  .Product-definition dd {
    padding-left: 10px;
    font-weight: normal; }
  @media screen and (min-width: 600px) {
    .Product-definition {
      width: 50%; } }
  @media screen and (min-width: 1200px) {
    .Product-definition {
      width: calc(1 / 3 * 100%); } }

.Product-cta {
  display: flex;
  justify-content: space-evenly;
  padding: 20px 0; }
  @media screen and (min-width: 600px) {
    .Product-cta {
      flex-direction: column;
      order: 2;
      padding: 20px; } }

.Product-ctaBtn {
  font-size: 15px;
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  padding: 15px 40px;
  font-weight: bold;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
  letter-spacing: 0.5px;
  display: inline-block;
  vertical-align: middle;
  background-color: #8cc7da;
  color: #34313f;
  box-shadow: none;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  transition: background-color 0.15s ease-in-out;
  text-align: center;
  cursor: pointer;
  pointer-events: all; }
  .Product-ctaBtn i {
    font-size: 18px;
    margin-right: 5px;
    vertical-align: middle; }
  .Product-ctaBtn:hover {
    color: #34313f; }
  @media screen and (min-width: 600px) {
    .Product-ctaBtn {
      border-radius: 0 3px 0 0;
      order: 1; } }

.Product-footer {
  width: 100%;
  padding: 0 20px 20px; }
  @media screen and (min-width: 600px) {
    .Product-footer {
      position: absolute;
      background-color: #fff;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      padding: 0;
      width: 160px; } }

.Product-ctaItem {
  display: flex;
  align-items: center;
  color: #34313f; }
  .Product-ctaItem i {
    font-size: 16px;
    color: #34313f;
    margin-right: 5px; }
  @media screen and (min-width: 600px) {
    .Product-ctaItem {
      margin-bottom: 5px; } }

.Product-pagText {
  display: block;
  color: #fff;
  font-size: 14px;
  font-style: italic;
  margin-top: 10px; }
  .Product-pagText > span:first-child {
    margin-right: 4px;
    font-weight: bold; }

.Hero {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column-reverse;
  margin-top: -5px; }
  @media screen and (min-width: 600px) {
    .Hero {
      padding-left: 25px;
      padding-right: 25px;
      max-width: 1360px;
      margin: 0 auto; } }
  @media screen and (min-width: 600px) {
    .Hero {
      margin: 0 auto; } }
  .Hero-cta {
    width: 100%;
    position: relative; }
    .Hero-cta h1 {
      margin-bottom: 30px; }
  .Hero-img {
    margin-bottom: 30px; }
  .Hero-signup {
    display: flex;
    flex-direction: column; }
    .Hero-signup button {
      margin-top: 20px;
      align-self: flex-start;
      min-width: 160px; }
    .Hero-signup input {
      font-size: 18px;
      background-color: #686575;
      border: none;
      padding: 15px 20px;
      outline: none;
      border-radius: 4px;
      box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.25);
      width: 100%;
      color: #fff; }
      .Hero-signup input::-webkit-input-placeholder {
        color: #fff;
        font-family: "Roboto Condensed", sans-serif; }
      .Hero-signup input::-moz-placeholder {
        color: #fff;
        font-family: "Roboto Condensed", sans-serif; }
      .Hero-signup input:-ms-input-placeholder {
        color: #fff;
        font-family: "Roboto Condensed", sans-serif; }
      .Hero-signup input:-moz-placeholder {
        color: #fff;
        font-family: "Roboto Condensed", sans-serif; }
  .Hero-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px; }
    .Hero-row > *:not(:first-child) {
      margin-left: 10px; }
  @media screen and (min-width: 600px) {
    .Hero {
      flex-direction: row;
      margin-top: 0; }
      .Hero-cta {
        align-self: center;
        width: 60%; }
        .Hero-cta h1 {
          margin-bottom: 50px; }
      .Hero-img {
        display: block;
        width: 40%;
        margin-bottom: 0; }
        .Hero-img img {
          position: relative;
          max-width: 200%;
          right: -7%;
          top: -8%; } }
  @media screen and (min-width: 960px) {
    .Hero-cta {
      width: 50%; }
    .Hero-img img {
      max-width: none; } }
  @media screen and (min-width: 1200px) {
    .Hero-cta {
      width: 40%; }
    .Hero-img {
      width: 60%; } }

@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae; }
  to {
    width: 0;
    background: transparent; } }

@keyframes inputHighlighter {
  from {
    background: #5264ae; }
  to {
    width: 0;
    background: transparent; } }

.Contact {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  overflow-y: auto;
  z-index: 10;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: rgba(52, 49, 63, 0.95); }
  .Contact-body {
    max-width: 700px;
    margin: 0 auto; }
  .Contact-content {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.25);
    padding: 40px 20px; }
  .Contact-title {
    margin-bottom: 20px;
    font-size: 18px; }
    @media screen and (min-width: 600px) {
      .Contact-title {
        font-size: 26px; } }
  .Contact-close {
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
    box-shadow: none;
    position: absolute;
    top: 30px;
    right: 30px;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    .Contact-close > i {
      font-size: 32px;
      margin-left: 4px; }
    .Contact-close:hover {
      color: #fff;
      -webkit-transform: scale(1.05);
              transform: scale(1.05); }
  .Contact-img {
    margin: 0 auto;
    position: relative; }
    .Contact-img--contracted {
      max-width: 275px; }
  .Contact-expand {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 10px;
    padding: 3px 5px;
    box-shadow: none;
    display: flex;
    align-items: center; }
  .Contact .RadioToggle {
    margin-top: 40px;
    margin-bottom: 30px; }
  .Contact .Form-success {
    text-align: center; }

.Form-success, .Form-error {
  text-align: left;
  font-size: 15px;
  margin-top: 3px;
  margin-left: 5px;
  display: flex;
  align-items: center; }
  .Form-success > i, .Form-error > i {
    margin-right: 10px; }

.Form-center {
  display: flex;
  justify-content: center; }

.Form-success {
  color: #28a745; }

.Form-error {
  color: #dc3545; }

.Form-row {
  display: flex;
  margin-bottom: 30px; }
  .Form-row:last-of-type {
    margin-bottom: 15px; }

.Form-alt {
  margin-top: 15px; }

.Form-submit {
  margin-top: 15px; }

.Form-group {
  flex-grow: 1;
  position: relative;
  margin: 10px;
  font-size: 18px; }
  .Form-group input,
  .Form-group textarea {
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #34313f; }
    .Form-group input:focus,
    .Form-group textarea:focus {
      outline: none; }
  .Form-group textarea {
    border: 1px solid #34313f;
    border-radius: 4px;
    padding: 10px; }
    .Form-group textarea:focus {
      border: 2px solid #6200ee;
      padding: 9px; }
    .Form-group textarea ~ label {
      left: 10px; }
    .Form-group textarea:focus ~ label,
    .Form-group textarea:valid ~ label {
      top: -25px;
      left: 5px;
      font-size: 14px;
      color: #6200ee; }
  .Form-group label {
    color: #999;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all; }
  .Form-group input:focus ~ label,
  .Form-group input:valid ~ label {
    top: -20px;
    font-size: 14px;
    color: #6200ee; }
  .Form-group-bar {
    position: relative;
    display: block;
    width: 100%; }
    .Form-group-bar::before, .Form-group-bar::after {
      content: "";
      height: 2px;
      width: 0;
      bottom: 1px;
      position: absolute;
      background: #6200ee;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all; }
    .Form-group-bar::before {
      left: 50%; }
    .Form-group-bar::after {
      right: 50%; }
  .Form-group input:focus ~ .Form-group-bar:before,
  .Form-group input:focus ~ .Form-group-bar:after,
  .Form-group textarea:focus ~ .Form-group-bar:before,
  .Form-group textarea:focus ~ .Form-group-bar:after {
    width: 50%; }
  .Form-group-highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5; }
  .Form-group input:focus ~ .Form-group-highlight,
  .Form-group textarea:focus ~ .Form-group-highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
            animation: inputHighlighter 0.3s ease; }
  .Form-group--error input,
  .Form-group--error textarea {
    border-color: #dc3545; }
  .Form-group--error .Form-group-bar::before,
  .Form-group--error .Form-group-bar::after {
    background-color: #dc3545; }

.RadioToggle {
  background-color: #686575;
  border-radius: 10px;
  display: inline-block;
  position: relative;
  width: 300px; }
  @media screen and (min-width: 600px) {
    .RadioToggle {
      width: 360px; } }
  .RadioToggle::before {
    content: "";
    background-color: #fff;
    position: absolute;
    top: -3px;
    left: 0;
    display: inline-block;
    height: 47px;
    width: 50%;
    border-radius: 10px;
    transition: -webkit-transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.25); }
  .RadioToggle-item {
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 10px 10px;
    width: 50%;
    font-weight: bold;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: color 0.25s ease-in-out; }
    @media screen and (min-width: 600px) {
      .RadioToggle-item {
        font-size: 15px;
        padding: 10px 15px; } }
    .RadioToggle-item:first-of-type {
      color: #52aac8; }
    .RadioToggle-item input {
      opacity: 0;
      position: absolute; }
  .RadioToggle--right::before {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  .RadioToggle--right .RadioToggle-item {
    color: #52aac8; }
    .RadioToggle--right .RadioToggle-item:first-of-type {
      color: #fff; }

.Vendor {
  margin-top: -15%;
  color: #34313f; }
  @media screen and (min-width: 600px) {
    .Vendor {
      margin-top: 0; } }
  .Vendor-bg {
    background: linear-gradient(#ffb048, #ffdf43);
    margin-top: -1px;
    padding: 40px 0; }
  .Vendor-top {
    width: 100%;
    min-width: 960px;
    display: block; }
  .Vendor-wrapper {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    text-align: center; }
    @media screen and (min-width: 600px) {
      .Vendor-wrapper {
        padding-left: 25px;
        padding-right: 25px;
        max-width: 1360px;
        margin: 0 auto; } }
    .Vendor-wrapper::after {
      content: '';
      display: table;
      clear: both; }
    @media screen and (min-width: 600px) {
      .Vendor-wrapper {
        flex-direction: row-reverse;
        align-items: center;
        max-width: 760px;
        text-align: left; } }
  @media screen and (min-width: 600px) {
    .Vendor-img {
      margin-left: 50px; } }
  .Vendor-content {
    max-width: 450px;
    margin: 40px auto 0; }
    .Vendor-content p {
      margin: 20px 0 40px; }
  .Vendor-decoration {
    position: relative;
    margin-top: 100px; }
    .Vendor-decoration img {
      display: block;
      margin: 0 auto; }
    .Vendor-decoration::before, .Vendor-decoration::after {
      content: "";
      position: absolute;
      height: 2px;
      width: calc(50% - 60px);
      background-color: #34313f;
      top: 50%; }
    .Vendor-decoration:after {
      right: 0; }

.Newsletter {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  margin-top: -5px;
  padding-top: 100px; }
  @media screen and (min-width: 600px) {
    .Newsletter {
      padding-left: 25px;
      padding-right: 25px;
      max-width: 1360px;
      margin: 0 auto; } }
  @media screen and (min-width: 600px) {
    .Newsletter {
      margin: 0 auto; } }
  .Newsletter .Form-error,
  .Newsletter .Form-success {
    text-align: center;
    margin-top: 10px; }
  .Newsletter-cta {
    width: 100%;
    position: relative;
    max-width: 600px;
    margin: 0 auto; }
    .Newsletter-cta h1 {
      margin-bottom: 30px; }
  .Newsletter-signup {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; }
    .Newsletter-signup button {
      margin-top: 20px;
      align-self: center;
      min-width: 160px; }
    .Newsletter-signup input {
      font-size: 18px;
      background-color: #686575;
      border: none;
      padding: 15px 20px;
      outline: none;
      border-radius: 4px;
      box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.25);
      width: 100%;
      color: #fff; }
      .Newsletter-signup input::-webkit-input-placeholder {
        color: #fff;
        font-family: "Roboto Condensed", sans-serif; }
      .Newsletter-signup input::-moz-placeholder {
        color: #fff;
        font-family: "Roboto Condensed", sans-serif; }
      .Newsletter-signup input:-ms-input-placeholder {
        color: #fff;
        font-family: "Roboto Condensed", sans-serif; }
      .Newsletter-signup input:-moz-placeholder {
        color: #fff;
        font-family: "Roboto Condensed", sans-serif; }
  .Newsletter-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px; }
    .Newsletter-row > *:not(:first-child) {
      margin-left: 10px; }
  @media screen and (min-width: 600px) {
    .Newsletter {
      flex-direction: row;
      margin-top: 0; }
      .Newsletter-cta {
        align-self: center; }
        .Newsletter-cta h1 {
          margin-bottom: 50px; } }

/* Component */
.Loader {
  margin: 0 0 2em;
  height: 100px;
  width: 20%;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top; }
  .Loader path,
  .Loader rect {
    fill: #ff914b; }
    .Loader path:nth-child(2),
    .Loader rect:nth-child(2) {
      fill: #ffc04b; }
    .Loader path:nth-child(3),
    .Loader rect:nth-child(3) {
      fill: #ffdf43; }

.Pagination {
  background-color: rgba(255, 255, 255, 0.97);
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: inline-flex;
  margin: 20px auto;
  line-height: 1;
  max-width: 100%;
  overflow: auto; }
  .Pagination-arrow {
    padding: 12px 16px; }
    .Pagination-arrow:first-child {
      border-right: 1px solid #686575; }
    .Pagination-arrow--disabled {
      color: gray;
      pointer-events: disabled;
      cursor: not-allowed; }
  .Pagination-item {
    padding: 12px 18px;
    border-right: 1px solid #686575;
    font-size: 18px;
    line-height: 24px; }
    .Pagination-item--active {
      color: #34313f;
      pointer-events: disabled;
      background-color: #e5e5e5; }
  .Pagination-nums {
    display: flex; }
